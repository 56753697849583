import React from "react"
import { graphql } from "gatsby"
//import FeatureImage from "../components/featureImage"
import Card from "../components/card"
import Paginate from "../components/paginate"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ImageAd from "../components/imageAd"
import ImageAdMob from "../components/imageAdMob" 
import IconPeru from "../components/iconPeru"
import IconVenezuela from "../components/iconVenezuela"
import IconColombia from "../components/iconColombia"
import IconArgentina from "../components/iconArgentina"
import WebAmigas from "../components/webAmigas"


const allPosts = ({pageContext, data}) => {
    const {currentPage, numPages} = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/" : `/${currentPage - 1}`
    const nextPage = `/${currentPage + 1}`

    //const posts = data.allMdx.edges   
    const posts = data.debutantes.edges
    //const kines_lima = data.lima.group
    

    return (
        <Layout>
            <SEO 
                title={"Kinesiólogas y escorts en Perú | Kinesiólogas Extranjeras"}
                keywords={[`kinesiólogas extranjeras`, `putas extranjeras`, `kinesiólogas en lima`, `kinesiólogas en perú`, `kinesiologas venezolanas`, `kinesiologas colombianas`, `kinesiologas argentinas`]}
            />
            <section className="section-top-cards">
                <div className="container">
                    <div className="ext-grid">
                        <article className="ext-card ven">
                            <div className="ext-card-title">
                            <IconVenezuela /> Venezolanas
                            </div>
                            <div className="ext-card-followers">
                                <span className="ext-card-followers-number">+ 1500</span>
                                <div className="ext-card-followers-title">KINESIOLOGAS</div>
                            </div>
                            <div className="ext-card-today">
                                <trio></trio> <span>Nuevas</span>
                            </div>
                        </article>
                        <article className="ext-card col">
                            <div className="ext-card-title">
                                <IconColombia /> Colombianas
                            </div>
                            <div className="ext-card-followers">
                                <span className="ext-card-followers-number">+ 800</span>
                                <div className="ext-card-followers-title">KINESIOLOGAS</div>
                            </div>
                            <div className="ext-card-today">
                                <trio></trio> <span>Nuevas</span>
                            </div>
                        </article>                        
                        <article className="ext-card arg">
                            <div className="ext-card-title">
                            <IconArgentina /> Argentinas
                            </div>
                            <div className="ext-card-followers">
                                <span className="ext-card-followers-number">+ 350</span>
                                <div className="ext-card-followers-title">KINESIOLOGAS</div>
                            </div>
                            <div className="ext-card-today">
                                <trio></trio> <span>Nuevas</span>
                            </div>
                        </article>
                        <article className="ext-card per">
                            <div className="ext-card-title">
                            <IconPeru /> Peruanas
                            </div>
                            <div className="ext-card-followers">
                                <span className="ext-card-followers-number">+ 200</span>
                                <div className="ext-card-followers-title">KINESIOLOGAS</div>
                            </div>
                            <div className="ext-card-today">
                                <trio></trio> <span>Nuevas</span>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
            <section className="section-news">
                <div className="container">
                <h2>Kinesiólogas debutantes en Perú</h2>
                    <div className="ext-grid">
                        {posts.map(post => (
                            <Card
                                key={post.node.frontmatter.slug}
                                country={post.node.frontmatter.country}
                                currency={post.node.frontmatter.currency}
                                precio={post.node.frontmatter.precio}
                                name={post.node.frontmatter.name}
                                slug={post.node.frontmatter.slug}
                                nueva={post.node.frontmatter.nueva}
                                foto={post.node.frontmatter.featureImage.childImageSharp.fixed}
                            />
                        ))}     
    
                    </div>
                    <Paginate 
                        isFirst={isFirst}
                        isLast={isLast}
                        prevPage={prevPage}
                        nextPage={nextPage}
                    />
                </div>
            </section>
            
            <section className="sectioncredits">
            <div className="container">
                <p style={{marginTop:`30px`,color:`var(--ext-purple)`}}>
                    <strong>ATENCIÓN</strong>: Este sitio está dirigido a persona <strong>mayores de 18 años de edad</strong>, si eres menor no entres. 
                    Todas las personas que se anuncian aquí son mayores de 18 años y con autorización de las mismas.
                </p>
                <p>
                    En <strong>kinesiólogas Extranjeras</strong> podrás encontrar rápidamente escorts, modelos, anfitrionas A1, kinesiólogas y damas de compañía. 
                    Selecciona a tu chica favorita entre los miles de anuncios que se publican, el contenido del anuncio son autorizadas por ellas mismas, todas con fotos y videos 
                    originales de jovencitas y maduritas.
                </p>
                <p> Esta página web es <strong>solo para chicas</strong> que quieran anunciar ;)</p>
                <h3 style={{fontWeight: `normal`}}>Puedes escribirnos al número 945 689 142 ;) <strong>No se contestan llamadas</strong>.</h3>
            </div>
            <ImageAd />
            <ImageAdMob />
            <WebAmigas />
            <div className="container content-terms">                
                <h3 style={{marginTop:`30px`}}>Está prohibida la copia parcial o total de nuestra página web</h3>
                <p>
                    Las chicas cuyas fotos, videos o avisos que se hayan publicado en este sitio web, fueron publicados con el consentimiento de ellas mismas y no tienen ninguna vinculación laboral, profesional o de cualquier índole con kinesiologasextranjeras.com.
        NO SOMOS AGENCIA, somos un directorio para escorts, modelos, anfitrionas A1, kinesiólogas y damas de compañía; todas mayores de edad.
                </p>  
                <p>
                Las conversaciones o acuerdos que haya entre las chicas que anuncian en <strong>Kinesiólogas Extranjeras</strong> con terceras personas, 
                    corren por su responsabilidad y consentimiento, no teniendo kinesiologasextranjeras.com participación alguna. Por ende no son de interés ni responsabilidad del autor de esta página.
                </p>
                <h4 style={{color:`var(--ext-purple)`}}>Kinesiologasextranjeras.com se encuentra absolutamente en contra de la trata de personas y la explotación sexual.</h4>  
            </div>
        </section>

        </Layout>
    )
}

export default allPosts

export const pageQuery = graphql`
    query AllPostsQuery($skip: Int!, $limit: Int!) {
        debutantes: allMdx(
            sort: {fields: frontmatter___date, order: DESC}, 
            skip: $skip, 
            limit: $limit) {
            edges {
              node {
                frontmatter {
                  country
                  currency
                  precio
                  slug
                  name
                  nueva
                  featureImage {
                    publicURL
                    childImageSharp {
                      fixed {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
            }
        }
        
        

    }
`
//distinct(field: frontmatter___district)            
//add image lista de kines
