import React from "react"
import { CardDescription, CardName, CardCountry, CardPrice, CardCoin, CardNumber, CardNew, CardNewText } from "../elements"
import CardLink from "./CardLink"
import ImageFixed from "./imageFixed"
import IconPeru from "./iconPeru"
import IconColombia from "./iconColombia"
import IconVenezuela from "./iconVenezuela"
import IconEcuador from "./iconEcuador"

const Card = ({slug, country, currency, precio, nueva, name, foto, vaca}) => {
    
    if (country === 'ECU') {
        vaca = <IconEcuador />
    } else if (country === 'COL') {
        vaca = <IconColombia />
    } else if (country === 'VEN') {
        vaca = <IconVenezuela />
    } else if (country === 'ARG') {
        vaca = 'ARGENTINA'
    } else {
        vaca = <IconPeru />
    }

    if(precio === 0) {
        precio = ''
        currency = 'Ofertón'
    }

    return <CardLink href={slug}>
            <ImageFixed fixed={foto} />
            <CardDescription>
                <CardName>{name}</CardName>
                <CardCountry>{vaca}</CardCountry>
                <CardPrice>
                    <CardCoin>{currency}</CardCoin>
                    <CardNumber>{precio}</CardNumber>
                </CardPrice>
                <CardNew>
                    <CardNewText>{nueva}</CardNewText>
                </CardNew>
            </CardDescription>
        </CardLink>
}

export default Card
