import React from "react"
import Img from "gatsby-image"
import {useStaticQuery, graphql} from "gatsby"
import { ImageAdWrapper } from "../elements"


const ImageAd = ({fixed}) => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "ANUNCIOS-KINESIOLOGAS-EXTRANJERAS-PERU.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1366) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return <ImageAdWrapper>
        <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="anuncios Kinesiólogas extranjeras perú" />        
    </ImageAdWrapper>
}

export default ImageAd
