import React from "react"
import Img from "gatsby-image"
import {useStaticQuery, graphql} from "gatsby"
import { ImageAdMobWrapper } from "../elements"


const ImageAdMob = ({fixed}) => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "ANUNCIOS-KINESIOLOGAS-EXTRANJERAS.png" }) {
                childImageSharp {
                    fluid(maxWidth: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return <ImageAdMobWrapper>
        <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="ANUNCIOS KINES EXTRANJERAS" />        
    </ImageAdMobWrapper>
}

export default ImageAdMob
