
import React from "react"
import { WebAmigasWrapper } from "../elements"

const WebAmigas = () => (
    <WebAmigasWrapper
    style={{
        marginTop: `40px`,
        }}
    >
        <div className="content-friend">
            <a href="https://www.worldescortindex.com/" target="_blank" title="worldescortindex.com" rel="noreferrer">
                <img src="https://www.worldescortindex.com/images/our-banners/120x60.jpg" alt="worldescortindex.com" />
            </a>
            <a href="https://openadultdirectory.com/escorts/" target="_blank" rel="noreferrer">
                <img src="https://img.openadultdirectory.com/banner-img/escorts-md.jpg" border="0" alt="OpenAdultDirectory  Escorts" />
            </a>
            <a href="https://www.world-escort-guide.com/" title="Escort Directory" target="_blank" className="world-escort-guide" rel="noreferrer">
                <img src="https://www.world-escort-guide.com/assets/images/image8.jpg" alt="Escort Directory" />
            </a>
            <a href="https://www.escort-links.com" title="Escort Links" target="_blank" rel="noreferrer">
                <img src="https://www.escort-links.com/images/escortlinks-1.jpg" alt="Escort Links" />
            </a>
            <a href="https://www.eurogirlsescort.com" target="_blank" title="EuroGirlsEscort.com" rel="noreferrer">
                <img src="https://www.eurogirlsescort.com/dist/images/banners/120X60.jpg" alt="EuroGirlsEscort.com" title="EuroGirlsEscort.com" />
            </a>            
        </div>        
    </WebAmigasWrapper>
    
)



export default WebAmigas
