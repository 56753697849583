import React from "react"
import { PaginateWrapper, PaginateElement } from "../elements"


const Paginate = ({isFirst, isLast, prevPage, nextPage}) => {
    return <PaginateWrapper isFirst={isFirst} isLast={isLast}>
        <PaginateElement to={prevPage}>Atrás</PaginateElement>
        <PaginateElement to={nextPage}>Adelante</PaginateElement>
    </PaginateWrapper>
}

export default Paginate
