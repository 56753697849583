import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import { ImageCountryWrapper } from "../elements"

const IconColombia = ({fixed}) => {
    const data = useStaticQuery(graphql`
        query {
            icon: file(relativePath: { eq: "svg/bandera-colombia.svg"}) {
                publicURL
            }
        }    
    `)
    return <ImageCountryWrapper>
        <img src={fixed ? fixed : data.icon.publicURL} alt="Kinesiólogas Colombianas" />        
    </ImageCountryWrapper>
}

export default IconColombia
